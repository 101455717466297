import React, { useState } from "react";
import { Card, Label, Row, Col } from "reactstrap";
import Content from "../../../layout/content/Content";
import {
  ActionButton,
  BalanceBadge,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  CancelButton,
  ConfirmationAlert,
  FormCheckbox,
  FormDatePicker,
  FormInput,
  FormInputType,
  Icon,
  LoadingComponent,
  RadioButtonOptions,
} from "../../../components/Component";
import { useSelector } from "react-redux";
import {
  fetchLockUnlock,
  fetchQuestionList,
  fetchRealBalance,
  fetchRecoDateList,
  fetchRecoPdf,
  fetchSignOff,
  fetchTransactionReco,
  fetchUnlockRequest,
} from "./RecoData";
import { useEffect } from "react";
import {
  CURRENCY_CODE,
  covertToNumberOnly,
  numberFormatter,
} from "../../../utils";
import moment from "moment";
import { DateModal } from "../../../components/DateModal/DateModal";
import { PermissionType } from "../../../components/dropdowns/PermissionDropdown";
import LockButton from "../../../components/button/LockButton";
import { formatAmount } from "../../../components/input/FormInput/FormInput";
import {
  openLinkInNewTab,
  removeTimeFromServerDateString,
  showErrorToast,
} from "../../../utils/Functions";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
  isCheckToList,
} from "../../../services/permission/permission";
import ExportButton from "../../../components/button/ExportButton";
import CustomButton from "../../../components/button/CustomButton";
import {
  CustomButtonTypes,
  EXPORT_TYPE,
  File_TYPE,
  RadioOptionTypes,
  RecoType,
} from "../../../utils/Utils";
import Head from "../../../layout/head/Head";
import RecoDateNameComponent from "./RecoDateNameComponent";
import RecoCommentModal from "./RecoCommentModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Reco = () => {
  const [bankexplanation, setBankExplanation] = useState("");
  const [suspenseExplanation, setSuspenseExplanation] = useState("");
  const [isBankExplanationVisible, setIsBankExplanationVisible] =
    useState(false);
  const [isSuspenseExpVisible, setIsSuspenseExpVisible] = useState(false);
  const [cashexplanation, setCashExplanation] = useState("");
  const [isCashExplanationVisible, setIsCashExplanationVisible] =
    useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const location = useSelector((state) => state?.auth?.currentLocation);
  const [transactionData, setTransactionData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [bankAmount, setBankAmount] = useState();
  const [cashAmount, setCashAmount] = useState();
  const sessionUser = useSelector((state) => state?.auth?.sessionUser);
  const userType = sessionUser?.userType;
  const [signOffDate, setSignOffDate] = useState(new Date());
  const [signOffName, setSignOffName] = useState();
  const [showLockButton, setShowLockButton] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [initScreen, setInitScreen] = useState(false);
  const [isExplanation, setIsExplanation] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  // const [questionMessages, setQuestionMessages] = useState({});
  const [isSignedOff, setIsSignedOff] = useState(false);
  const [printLoading, setPrintLoading] = useState(false);
  const [commentModal, setCommentModal] = useState(false);
  const [commentData, setCommentData] = useState();
  const [recoId, setRecoId] = useState();
  const [recoDate, setRecoDate] = useState(new Date());
  const [recoDateList, setRecoDateList] = useState([]);
  const [showOptions, setShowOptions] = useState(true);
  const [isNewRecoPrimary, setIsNewRecoPrimary] = useState(true);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [selectedRecoDate, setSelectedRecoDate] = useState(null);
  const [selectedRecoMonth, setSelectedRecoMonth] = useState();
  const [selectedRecoType, setSelectedRecoType] = useState(RecoType?.monthly);
  const [isRecoFetchOnLoad, setIsRecoFetchOnLoad] = useState(true);
  const locationState = useLocation();

  useEffect(() => {
    if (locationState?.state?.isRecoView) {
      let date = moment(
        removeTimeFromServerDateString(locationState?.state?.recoDate),
        "YYYY-MM-DD"
      ).toDate();
      setSelectedDate(date);
    }
  }, [locationState?.state?.isRecoView]);

  useEffect(() => {
    loadTransaction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    selectedDate,
    recoDate,
    selectedRecoType,
    selectedRecoDate,
    selectedRecoMonth,
  ]);

  useEffect(() => {
    loadRecoDateList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedDate, locationState]);

  useEffect(() => {
    questionListing();
  }, []);

  useEffect(() => {
    setRecoId(transactionData?.recoRealBalance?.id);
    if (transactionData?.recoRealBalance) {
      setIsExplanation(true);
      setBankExplanation(
        transactionData?.recoRealBalance?.bankExplanation ?? ""
      );
      setCashExplanation(
        transactionData?.recoRealBalance?.cashExplanation ?? ""
      );
      setSuspenseExplanation(
        transactionData?.recoRealBalance?.suspenseExplanation ?? ""
      );
      setBankAmount({
        ...bankAmount,
        amount: formatAmount(
          "",
          transactionData?.recoRealBalance?.realBankBalance + ""
        ),
      });
      setCashAmount({
        ...cashAmount,
        amount: formatAmount(
          "",
          transactionData?.recoRealBalance?.realCashBalance + ""
        ),
      });
    } else {
      setBankAmount();
      setCashAmount();
      setIsExplanation(false);
    }
    if (transactionData?.isLocked === true) {
      setShowLockButton(true);
    } else {
      setShowLockButton(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionData]);

  useEffect(() => {
    if (transactionData?.answers) {
      const preSelectedAnswers = [];
      transactionData?.answers?.forEach((answer) => {
        preSelectedAnswers.push({
          id: answer.signOffQuestionId,
          answer: answer.answer,
          description: answer.description,
        });
      });
      setAnswers(preSelectedAnswers);
      setIsSignedOff(preSelectedAnswers.length > 0);
    }
  }, [transactionData]);

  useEffect(() => {
    handleNewRecoClick();
  }, [selectedDate]);

  // *********************API**********************

  const loadTransaction = () => {
    if (!location) return;
    isCheckToList(UserPermissionType.reco) && setInitScreen(true);
    let params = {
      locationId: location ? location.id : null,
      month: selectedDate?.getMonth() + 1,
      year: selectedDate?.getFullYear(),
      type: selectedRecoDate ? RecoType.weekly : selectedRecoType,
      recoDate: selectedRecoDate
        ? selectedRecoDate
        : selectedRecoType === RecoType.weekly
        ? moment(recoDate).format("YYYY-MM-DD")
        : undefined,
      // recoId: 0,
    };
    fetchTransactionReco(params, (data, error) => {
      if (error === null) {
        setTransactionData(data);
      }
      setInitScreen(false);
    });
  };

  const calculateTotalDiff = () => {
    if (transactionData) {
      const totalDiff =
        transactionData.totalBankBalance +
        transactionData.totalCashtin -
        (transactionData.totalResidentAmount + transactionData.suspenceBalance);
      return totalDiff.toFixed(2);
    }
    return 0;
  };

  const loadRealBalance = () => {
    let params = {
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
      realCashBalance: parseFloat(covertToNumberOnly(cashAmount?.amount)),
      realBankBalance: parseFloat(covertToNumberOnly(bankAmount?.amount)),
      locationId: location ? location.id : null,
      bankExplanation: bankexplanation,
      cashExplanation: cashexplanation,
      suspenseExplanation: suspenseExplanation,
      type: selectedRecoType,
      recoDate: selectedRecoDate
        ? moment(selectedRecoDate).format("YYYY-MM-DD")
        : selectedRecoType === RecoType.weekly
        ? moment(recoDate).format("YYYY-MM-DD")
        : undefined,
    };
    setIsSaving(true);
    fetchRealBalance(params, (data, error) => {
      if (error === null) {
        loadTransaction();
      }
      setIsExplanation(true);
      setIsSaving(false);
    });
  };

  const loadLockUnlock = () => {
    let params = {
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
      locationId: location ? location.id : null,
      type: selectedRecoDate ? RecoType.weekly : selectedRecoType,
      recoDate: selectedRecoDate
        ? moment(selectedRecoDate).format("YYYY-MM-DD")
        : selectedRecoType === RecoType.weekly
        ? moment(recoDate).format("YYYY-MM-DD")
        : undefined,
    };
    fetchLockUnlock(params, (data, error) => {
      if (error === null) {
        loadTransaction();
      }
    });
  };

  const saveAnswers = (updatedAnswers) => {
    if (!recoId) return;

    let params = {
      name: signOffName?.name,
      date: signOffDate ? moment(signOffDate).format("YYYY-MM-DD") : undefined,
      recoId: recoId,
      comment: signOffName?.comment,
      answers: [],
      isSignOff: false,
    };
    if (userType === PermissionType.staff) {
      params.answers = updatedAnswers.map((ans) => ({
        signOffQueId: ans.id,
        answer: ans.answer,
        description: ans.description,
      }));
    }
    fetchSignOff(params, (data, error) => {
      if (error === null) {
      }
    });
  };

  const handleSignOff = () => {
    let params = {
      name: signOffName?.name,
      date: moment(signOffDate).format("YYYY-MM-DD"),
      recoId: recoId,
      comment: signOffName?.comment,
      isSignOff: true,
      answers: [],
    };

    if (userType === PermissionType.staff) {
      params.answers = answers.map((ans) => ({
        signOffQueId: ans.id,
        answer: ans.answer,
        description: ans.description,
      }));
    }
    fetchSignOff(params, (data, error) => {
      if (error === null) {
        handleNewRecoClick();
        loadTransaction();
        loadRecoDateList();
      }
    });
    setIsSignedOff(true);
  };

  const questionListing = () => {
    fetchQuestionList((data, error) => {
      if (error === null) {
        setQuestions(data.list);
      }
    });
  };

  const recoPdfExport = () => {
    let params = {
      month: selectedDate.getMonth() + 1,
      year: selectedDate.getFullYear(),
      locationId: location ? location.id : null,
      type: selectedRecoType,
    };
    fetchRecoPdf(params, (data, error) => {
      setPrintLoading(false);
      if (error === null) {
        openLinkInNewTab(data?.fileUrl);
      }
    });
  };

  const exportFile = (fileType) => {
    if (File_TYPE?.pdf === fileType) {
      recoPdfExport();
    }
  };
  const handleRequestToUnlock = () => {
    let params = {
      recoId: recoId,
      locationId: location ? location.id : null,
      // month: selectedDate.getMonth() + 1,
      // year: selectedDate.getFullYear(),
    };
    fetchUnlockRequest(params, (data, error) => {
      if (error === null) {
        loadTransaction();
      }
    });
  };

  const loadRecoDateList = () => {
    let params = {
      month: selectedDate?.getMonth() + 1,
      year: selectedDate?.getFullYear(),
      locationId: location ? location.id : null,
    };
    fetchRecoDateList(params, (data, error) => {
      if (error === null) {
        setRecoDateList(data?.list);
        if (
          (locationState?.state?.isRecoView &&
            locationState?.state?.recoType === RecoType.weekly &&
            isRecoFetchOnLoad) ||
          ((data?.list?.length ?? 0) > 0 &&
            data?.list.some((item) => item.recoType === RecoType.monthly))
        ) {
          let index = data?.list?.findIndex(
            (item) =>
              item.recoType === RecoType.weekly &&
              item.date === locationState?.state?.recoDate
          );
          if (index !== -1 && isRecoFetchOnLoad) {
            handleRecoDateButton(index, data?.list[index]);
            setIsRecoFetchOnLoad(false);
          } else if (
            (data?.list?.length ?? 0) > 0 &&
            data?.list.some((item) => item.recoType === RecoType.monthly)
          ) {
            handleRecoDateButton(0, data?.list[0]);
          }
        }
      }
    });
  };

  // ************* functions ************** //
  const onSignOff = () => {
    ConfirmationAlert("Sign Off", "Do you want to confirm Sign Off ?", () => {
      handleSignOff();
    });
  };

  const onLock = () => {
    ConfirmationAlert("Lock", "Do you want to confirm Lock ?", () => {
      loadLockUnlock();
    });
  };

  const onUnLock = () => {
    ConfirmationAlert("Unlock", "Do you want to confirm Unlock ?", () => {
      loadLockUnlock();
    });
  };

  const toggleModal = () => {
    setIsModalOpen(false);
    setSelectedData();
  };
  const handleClearButtonCheque = (item) => {
    setSelectedData(item);
    setIsModalOpen(true);
  };
  const handleClearButtonLodgement = (item) => {
    setSelectedData(item);
    setIsModalOpen(true);
  };
  const handleBankExplanation = () => {
    setIsBankExplanationVisible(false);
    loadRealBalance();
  };
  const handleCashExplanation = () => {
    setIsCashExplanationVisible(false);
    loadRealBalance();
  };
  const handleSuspenceExplanation = () => {
    setIsSuspenseExpVisible(false);
    loadRealBalance();
  };

  const handleYesNoClick = (queId, value) => {
    const existingAnswer = answers.find((ans) => ans.id === queId);
    if (existingAnswer && existingAnswer.answer === value) {
      // If the same value is clicked again, remove it from the answers
      const updatedAnswers = answers.filter((ans) => ans.id !== queId);
      setAnswers(updatedAnswers);
      saveAnswers(updatedAnswers); // save answer on server
    } else {
      // If a different value is clicked or no existing answer exists, update the state
      const updatedAnswers = answers.filter((ans) => ans.id !== queId);
      updatedAnswers.push({
        id: queId,
        answer: value === "Yes" ? "Yes" : value === "No" ? "No" : null,
      });
      setAnswers(updatedAnswers);
      saveAnswers(updatedAnswers); // save answer on server
    }
  };

  const handleTextAreaChange = (queId, event) => {
    // Update the answer for a specific question
    setAnswers((prevAnswers) => {
      return prevAnswers.map((ans) => {
        if (ans.id === queId) {
          return {
            ...ans,
            description: event.target.value,
          };
        }
        return ans;
      });
    });
  };

  const areAllCheckboxesChecked = () => {
    return questions.every((que) => {
      const isYesSelected = answers.some((ans) => ans.id === que.id);
      return isYesSelected;
    });
  };

  function isAllowToShowSignOff() {
    if (userType === PermissionType.staff) {
      return true;
    } else if (
      userType !== PermissionType.staff &&
      (transactionData?.recoSignOffList?.length ?? 0) > 0
    ) {
      return transactionData?.recoSignOffList?.some(
        (item) => item?.signOffUserType === "LOCATION_STAFF"
      );
    }
    return false;
  }

  const handleNewRecoClick = () => {
    setShowOptions(true);
    setIsNewRecoPrimary(true);
    setSelectedRecoType(RecoType.monthly);
    setSelectedRecoDate();
    setSelectedRecoMonth();
  };

  const handleRecoDateButton = (index, item) => {
    setSelectedButtonIndex(index);
    setSelectedRecoDate(item?.date);
    setSelectedRecoMonth(item?.month);
    setShowOptions(false);
    setIsNewRecoPrimary(false);
    setSelectedRecoType(item?.recoType);
  };

  const handleRecoDatePickerChange = (date) => {
    // Convert the selected date to the appropriate format
    const selectedDate = moment(date).format("YYYY-MM-DD");

    // Find the largest date available in recoDateList
    let largestDate = null;
    if (recoDateList && recoDateList.length > 0) {
      largestDate = moment(recoDateList[0].date);
      recoDateList.forEach((item) => {
        const currentDate = moment(item.date);
        if (currentDate.isAfter(largestDate)) {
          largestDate = currentDate;
        }
      });
    }

    // Check if the selected date is before the largest date in recoDateList
    if (largestDate && moment(selectedDate).isBefore(largestDate, "day")) {
      // Show an error message if the selected date is before the largest date
      showErrorToast("Reco date is locked");
      return;
    }

    // Update the state with the selected date
    setRecoDate(date);

    // Since a new date is selected, we want to keep the reco type as "Weekly"
  };
  let isMonthlySignOff =
    transactionData?.recoSignOffList?.find(
      (item) => item?.signOffUserType === "ADMIN"
    ) && transactionData?.recoType === "MONTHLY";

  return (
    <React.Fragment>
      <Head title="Reco" />
      <Content>
        <div className="reco-header">
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent className={"w-66"}>
                <BlockTitle
                  page
                  className={`${
                    isMonthlySignOff
                      ? "text-light bg-green align-center py-1 ps-1"
                      : ""
                  }`}
                >
                  {isMonthlySignOff ? "Reco - Completed" : "Reco"}
                  {isMonthlySignOff && (
                    <span>
                      <Icon name={"check-circle-fill"} className={"ps-1"} />
                    </span>
                  )}
                </BlockTitle>
              </BlockHeadContent>
              <BlockHeadContent>
                <div className="d-flex align-items-center">
                  {userType !== PermissionType.staff &&
                    isAllowToAccess(
                      UserPermissionType.reco,
                      UserActionType.lock
                    ) && (
                      <div className="d-flex justify-content-end">
                        {
                          !isNewRecoPrimary ? (
                            // Render LockButton if isNewRecoPrimary is false
                            !showLockButton ? (
                              <LockButton
                                title={"Lock"}
                                size={"md"}
                                onClick={() => {
                                  if (
                                    !bankAmount ||
                                    !bankAmount?.amount ||
                                    !cashAmount ||
                                    !cashAmount?.amount
                                  ) {
                                    showErrorToast(
                                      "Please Insert Bank or Cash Value"
                                    );
                                    return;
                                  }
                                  onLock();
                                }}
                                disabled={
                                  !bankAmount ||
                                  !bankAmount?.amount ||
                                  !cashAmount ||
                                  !cashAmount?.amount
                                }
                              />
                            ) : (
                              // Render UnlockButton if isNewRecoPrimary is false and showLockButton is true
                              isAllowToAccess(
                                UserPermissionType.reco,
                                UserActionType.unlock
                              ) && (
                                <LockButton
                                  title={"Unlock"}
                                  size={"md"}
                                  isUnlock={true}
                                  onClick={() => {
                                    onUnLock();
                                    // Show "Lock" button.
                                  }}
                                />
                              )
                            )
                          ) : null // Render null if isNewRecoPrimary is true
                        }
                      </div>
                    )}

                  {userType === PermissionType.staff &&
                    transactionData?.isLocked === true && (
                      <div className="d-flex justify-content-end mx-2">
                        <p>Locked</p>
                      </div>
                    )}

                  <span className="me-1">
                    <FormDatePicker
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      maxDate={new Date()}
                      placeholderText=""
                      selected={selectedDate}
                      onChange={(date) => {
                        setSelectedDate(date);
                        setRecoDate(date);
                      }}
                    />
                  </span>

                  {isAllowToAccess(
                    UserPermissionType.reco,
                    UserActionType.export
                  ) && (
                    <span>
                      <ExportButton
                        title={"Export"}
                        options={EXPORT_TYPE}
                        onChange={(fileType) => exportFile(fileType)}
                      />
                    </span>
                  )}

                  {isAllowToAccess(
                    UserPermissionType.reco,
                    UserActionType.print
                  ) && (
                    <span>
                      <CustomButton
                        label={""}
                        loading={printLoading}
                        type={CustomButtonTypes.Print}
                        onClick={() => {
                          setPrintLoading(true);
                          recoPdfExport();
                        }}
                      />
                    </span>
                  )}
                </div>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>
        </div>
        <Block className={"gy-2"}>
          <div className={`align-center mt-20px`}>
            <span>Previous RECO for this month</span>
            {!recoDateList.some(
              (item) => item.recoType === RecoType.monthly
            ) && (
              <span className="ms-5">
                <button
                  type="button"
                  className={`btn ${
                    isNewRecoPrimary ? "btn-primary" : "btn-white text-dark"
                  }`}
                  onClick={handleNewRecoClick}
                >
                  <Icon name={"plus"} />
                  <span>New Reco</span>
                </button>
              </span>
            )}

            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              {recoDateList?.map((item, index) => (
                <React.Fragment key={index}>
                  {(item?.date || item?.month) && (
                    <span className="ms-3">
                      <button
                        type="button"
                        className={`btn ${
                          isNewRecoPrimary
                            ? "btn-white text-dark"
                            : index === selectedButtonIndex
                            ? "btn-primary"
                            : "btn-white text-dark"
                        }`}
                        onClick={() => handleRecoDateButton(index, item)}
                      >
                        {item?.date
                          ? moment(item?.date).format("DD-MM-YYYY")
                          : item?.month
                          ? "Month Reco"
                          : null}
                      </button>
                    </span>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>

          {showOptions &&
            !recoDateList.some(
              (item) => item.recoType === RecoType.monthly
            ) && (
              <div className={`align-center date-picker-height`}>
                <>
                  <span className={`reco-text`}>
                    Previous RECO for this month
                  </span>
                  <span className="ms-5">
                    <RadioButtonOptions
                      name="type"
                      size={6}
                      optionType={RadioOptionTypes.weekorMonth}
                      onChange={(index) => {
                        let recoType =
                          index === 0
                            ? RecoType.monthly
                            : index === 1
                            ? RecoType.weekly
                            : null;
                        setSelectedRecoType(recoType);
                      }}
                      activeIndex={
                        selectedRecoType === RecoType.monthly ? 0 : 1
                      }
                    />
                  </span>
                  {selectedRecoType === RecoType.weekly && (
                    <span className="date-text ms-2">
                      <FormDatePicker
                        maxDate={moment(selectedDate).endOf("month").toDate()}
                        minDate={moment(selectedDate).startOf("month").toDate()}
                        placeholderText=""
                        selected={recoDate}
                        onChange={handleRecoDatePickerChange}
                      />
                    </span>
                  )}
                </>
              </div>
            )}

          <Row className="gy-5">
            <Col lg={8} className="col-7-container">
              <Card className="card-bordered">
                <div className="container">
                  <div className="row py-2">
                    <Col className={"col-md-5 d-flex justify-content-between"}>
                      <Label className="align-center mb-0">
                        Bank Balance Per Statement
                      </Label>
                      <FormInput
                        type={FormInputType.amount}
                        baseClass={"w-80"}
                        disabled={transactionData?.isLocked === true}
                        value={bankAmount?.amount || ""}
                        onTextChange={(text) => {
                          setBankAmount({
                            ...bankAmount,
                            amount: text,
                          });
                        }}
                      />
                    </Col>
                    <Col className={"col-md-5 d-flex justify-content-between"}>
                      <Label className="align-center mb-0">
                        Cash Per Tin Count
                      </Label>
                      <FormInput
                        type={FormInputType.amount}
                        baseClass={"w-80"}
                        disabled={transactionData?.isLocked === true}
                        value={cashAmount?.amount || ""}
                        onTextChange={(text) => {
                          setCashAmount({
                            ...cashAmount,
                            amount: text,
                          });
                        }}
                      />
                    </Col>
                    <Col className={"col-md-2 d-flex justify-content-end"}>
                      <ActionButton
                        title={"Save"}
                        size={"md"}
                        className={"w-75"}
                        disabled={transactionData?.isLocked === true}
                        loading={isSaving}
                        onClick={() => {
                          loadRealBalance();
                        }}
                      />
                    </Col>
                  </div>
                </div>
              </Card>
              <Card className="card-bordered mt-2">
                <div className="container">
                  <div className="row py-1">
                    <div className="col-sm-1 text-start fs-6 fw-medium">A</div>
                    <div className="col-sm-6 fs-6 fw-medium text-start">
                      Bank Balance As Per System
                    </div>
                    <div className="col-sm-5 text-end fs-6 fw-medium">
                      {transactionData?.totalBankBalance
                        ? numberFormatter(
                            CURRENCY_CODE,
                            "" + transactionData?.totalBankBalance
                          )
                        : CURRENCY_CODE + "0"}
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row py-1">
                    <div className="col-sm-1 text-end"></div>
                    <div className="col-sm-6">
                      <p className="mb-0 ms-3">a: Plus Unpresented Cheques</p>
                      <p className="mb-0 mt-1 ms-3">
                        b: Less Unpresented Lodgement
                      </p>
                    </div>
                    <div className="col-sm-5 text-end">
                      <p className="mb-0">
                        {transactionData?.totalUnpresentedAmount
                          ? numberFormatter(
                              CURRENCY_CODE,
                              "" + transactionData?.totalUnpresentedAmount
                            )
                          : CURRENCY_CODE + "0"}
                      </p>
                      <p className="mb-0 mt-1">
                        {transactionData?.totalUnpresentedLodgements
                          ? numberFormatter(
                              CURRENCY_CODE,
                              "" + transactionData?.totalUnpresentedLodgements
                            )
                          : CURRENCY_CODE + "0"}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row mt-2">
                    <div className="col-sm-1 text-end"></div>
                    <div className="col-sm-6">
                      <p className="mb-0 fs-6 fw-medium">
                        Adjusted Bank Balance
                      </p>
                    </div>
                    <div className="col-sm-5 text-end">
                      <span className="mb-0 fs-6 fw-medium">
                        <BalanceBadge
                          balance={
                            transactionData?.totalBankBalance
                              ? numberFormatter(
                                  CURRENCY_CODE,
                                  "" + transactionData?.adjustedBankAmount
                                )
                              : CURRENCY_CODE + "0"
                          }
                          isLow={
                            // transactionData?.recoRealBalance
                            //   ?.realBankBalance !==
                            // transactionData?.totalBankBalance
                            transactionData?.recoRealBalance
                              ?.realBankBalance !==
                            transactionData?.adjustedBankAmount
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {!transactionData?.isLocked && isExplanation && (
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-1 text-end"></div>
                      <div className="col-sm-6">
                        <p className="mb-0 fs-6 fw-medium"></p>
                      </div>
                      <div className="col-sm-5 text-end">
                        <p
                          className="mb-0 fs-span"
                          onClick={() =>
                            setIsBankExplanationVisible(
                              !isBankExplanationVisible
                            )
                          }
                        >
                          Explanation
                        </p>
                      </div>
                      {isBankExplanationVisible && (
                        <div>
                          <textarea
                            className="form-control"
                            placeholder="Write a Message"
                            maxLength={"200"}
                            value={bankexplanation}
                            onChange={(e) => setBankExplanation(e.target.value)}
                          />

                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 mt-1">
                            <li>
                              <CancelButton
                                onClick={() =>
                                  setIsBankExplanationVisible(
                                    !isBankExplanationVisible
                                  )
                                }
                              />
                            </li>
                            <li>
                              <ActionButton
                                title="Save"
                                onClick={handleBankExplanation}
                              />
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Bank explanation message */}
                <div className="container">
                  <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-11">
                      {bankexplanation && !isBankExplanationVisible && (
                        <p className="fs-12 mb-0 text-start">
                          {bankexplanation}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row mt-2">
                    <div className="col-sm-1 text-start fs-6 fw-medium">B</div>
                    <div className="col-sm-6">
                      <p className="mb-0 fs-6 fw-medium">
                        Cash Balance As Per System
                      </p>
                    </div>
                    <div className="col-sm-5 text-end">
                      <span className="mb-0 fs-6 fw-medium">
                        <BalanceBadge
                          balance={
                            transactionData?.totalCashtin
                              ? numberFormatter(
                                  CURRENCY_CODE,
                                  "" + transactionData?.totalCashtin
                                )
                              : CURRENCY_CODE + "0"
                          }
                          isLow={
                            transactionData?.recoRealBalance
                              ?.realCashBalance !==
                            transactionData?.totalCashtin
                          }
                        />
                      </span>
                    </div>
                  </div>
                </div>
                {!transactionData?.isLocked && isExplanation && (
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-1 text-end"></div>
                      <div className="col-sm-6">
                        <p className="mb-0 fs-6 fw-medium"></p>
                      </div>
                      <div className="col-sm-5 text-end">
                        <p
                          className="mb-0 fs-span"
                          onClick={() =>
                            setIsCashExplanationVisible(
                              !isCashExplanationVisible
                            )
                          }
                        >
                          Explanation
                        </p>
                      </div>
                      {isCashExplanationVisible && (
                        <div className="mb-2">
                          <textarea
                            className="form-control"
                            placeholder="Write a Message"
                            maxLength={"200"}
                            value={cashexplanation}
                            onChange={(e) => setCashExplanation(e.target.value)}
                          />

                          <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 mt-1">
                            <li>
                              <CancelButton
                                onClick={() =>
                                  setIsCashExplanationVisible(
                                    !isCashExplanationVisible
                                  )
                                }
                              />
                            </li>
                            <li>
                              <ActionButton
                                title="Save"
                                onClick={handleCashExplanation}
                              />
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {/* Cash explanation message */}
                <div className="container">
                  <div className="row">
                    <div className="col-sm-1"></div>
                    <div className="col-sm-11">
                      {cashexplanation && !isCashExplanationVisible && (
                        <p className="fs-12 mb-0 text-start">
                          {cashexplanation}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="container mb-1">
                  <div className="row mt-2">
                    <div className="col-sm-1 text-start fs-6 fw-medium">C</div>
                    <div className="col-sm-6">
                      <p className="mb-0 fs-6 fw-medium">Total Balance</p>
                    </div>
                    <div className="col-sm-5 text-end">
                      <span className="mb-0 fs-6 fw-medium">
                        <BalanceBadge
                          balance={
                            transactionData?.totalCashtin +
                            transactionData?.totalBankBalance
                              ? numberFormatter(
                                  CURRENCY_CODE,
                                  "" +
                                    // (transactionData?.totalCashtin +
                                    //   transactionData?.totalBankBalance)
                                    // (transactionData?.totalCashtin +
                                    //   adjustedBankAmount)
                                    (transactionData?.totalCashtin +
                                      transactionData?.totalBankBalance)
                                )
                              : CURRENCY_CODE + "0"
                          }
                          isLow={false}
                        />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="border-card-end"></div>
                {/* Resident Balance */}
                <div className="container">
                  <div className="row py-1">
                    <div className="col-sm-1 text-start">
                      <p className="mb-0  fs-6 fw-medium">D</p>
                    </div>
                    <div className="col-sm-6 fs-6 fw-medium">
                      <p className="mb-0  fs-6 fw-medium">Resident Balance</p>
                    </div>
                    <div className="col-sm-5 text-end fs-6 fw-medium">
                      <p className="mb-0  fs-6 fw-medium">
                        {transactionData?.totalResidentAmount
                          ? numberFormatter(
                              CURRENCY_CODE,
                              "" + transactionData?.totalResidentAmount
                            )
                          : CURRENCY_CODE + "0"}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Suspence Balance */}
                <div className="container">
                  <div className="row py-1">
                    <div className="col-sm-1 text-start fs-6 fw-medium">E</div>
                    <div className="col-sm-6 fs-6 fw-medium">
                      Suspence Balance
                    </div>
                    <div className="col-sm-5 text-end fs-6 fw-medium">
                      {transactionData?.suspenceBalance
                        ? numberFormatter(
                            CURRENCY_CODE,
                            "" + transactionData?.suspenceBalance
                          )
                        : CURRENCY_CODE + "0"}
                    </div>
                    {!transactionData?.isLocked && isExplanation && (
                      <div className="container">
                        <div className="row">
                          <div className="col-sm-1 text-end"></div>
                          <div className="col-sm-6">
                            <p className="mb-0 fs-6 fw-medium"></p>
                          </div>
                          <div className="col-sm-5 text-end">
                            <p
                              className="mb-0 fs-span"
                              onClick={() =>
                                setIsSuspenseExpVisible(!isSuspenseExpVisible)
                              }
                            >
                              Explanation
                            </p>
                          </div>
                          {isSuspenseExpVisible && (
                            <div>
                              <textarea
                                className="form-control"
                                placeholder="Write a Message"
                                maxLength={"200"}
                                value={suspenseExplanation}
                                onChange={(e) =>
                                  setSuspenseExplanation(e.target.value)
                                }
                              />

                              <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2 mt-1">
                                <li>
                                  <CancelButton
                                    onClick={() =>
                                      setIsSuspenseExpVisible(
                                        !isSuspenseExpVisible
                                      )
                                    }
                                  />
                                </li>
                                <li>
                                  <ActionButton
                                    title="Save"
                                    onClick={handleSuspenceExplanation}
                                  />
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="container">
                      <div className="row">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-11">
                          {suspenseExplanation && !isSuspenseExpVisible && (
                            <p className="fs-12 mb-0 text-start">
                              {suspenseExplanation}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Total Resident + Suspence Balance  */}
                <div className="container">
                  <div className="row py-1">
                    <div className="col-sm-1 text-start fs-6 fw-medium">F</div>
                    <div className="col-sm-6 fs-6 fw-medium">Total Balance</div>
                    <div className="col-sm-5 text-end fs-6 fw-medium">
                      {transactionData?.suspenceBalance +
                      transactionData?.totalResidentAmount
                        ? numberFormatter(
                            CURRENCY_CODE,
                            "" +
                              (transactionData?.suspenceBalance +
                                transactionData?.totalResidentAmount)
                          )
                        : CURRENCY_CODE + "0"}
                    </div>
                  </div>
                </div>
                <div className="border-card-end"></div>
                <div className="container">
                  <div className="row py-1">
                    <div className="col-sm-1 text-start fs-6 fw-medium">G</div>
                    <div className="col-sm-6 fs-6 fw-medium">Difference</div>
                    <div className="col-sm-5 text-end fs-6 fw-medium">
                      {calculateTotalDiff()
                        ? numberFormatter(
                            CURRENCY_CODE,
                            "" + calculateTotalDiff()
                          )
                        : CURRENCY_CODE + "0"}
                    </div>
                  </div>
                </div>
              </Card>
              {isAllowToAccess(
                UserPermissionType.reco,
                UserActionType.signoff
              ) && (
                <>
                  {isAllowToShowSignOff() ? (
                    <Card className="card-bordered mt-2">
                      <div>
                        <div className="px-2 py-1 fs-6">
                          <p>Sign Off</p>
                        </div>
                        <div className="border-card-end"></div>
                        <div className="my-2">
                          {transactionData?.recoType === RecoType.monthly &&
                            selectedRecoType === RecoType.monthly && (
                              <div className="container">
                                {questions.map((que) => {
                                  let answer = answers.find(
                                    (ans) => ans.id === que.id
                                  );
                                  return (
                                    <div className="row" key={que.id}>
                                      <div className="col-sm-1 col-12 text-start">
                                        {que.id}
                                      </div>
                                      <div className="col-sm-9 col-12 text-start">
                                        {que.queDescription}
                                      </div>
                                      <>
                                        <div className="col-sm-1 col-12 text-end">
                                          <FormCheckbox
                                            label={"Yes"}
                                            id={`yes-${que.id}`}
                                            name={`yes-${que.id}`}
                                            value="Yes"
                                            checked={answer?.answer === "Yes"}
                                            onChange={() =>
                                              handleYesNoClick(que.id, "Yes")
                                            }
                                            disabled={
                                              (isSignedOff &&
                                                transactionData?.isLocked ===
                                                  true) ||
                                              userType ===
                                                PermissionType.manager ||
                                              userType === PermissionType.admin
                                            }
                                          />
                                        </div>
                                        <div className="col-sm-1 col-12 text-end">
                                          <FormCheckbox
                                            label={"No"}
                                            id={`no-${que.id}`}
                                            name={`no-${que.id}`}
                                            value="No"
                                            checked={answer?.answer === "No"}
                                            onChange={() =>
                                              handleYesNoClick(que.id, "No")
                                            }
                                            disabled={
                                              (isSignedOff &&
                                                transactionData?.isLocked ===
                                                  true) ||
                                              userType ===
                                                PermissionType.manager ||
                                              userType === PermissionType.admin
                                            }
                                          />
                                        </div>
                                      </>
                                      {transactionData?.isLocked === false &&
                                        answers.some(
                                          (ans) =>
                                            ans.id === que.id &&
                                            ans.answer === "Yes"
                                        ) && (
                                          <>
                                            <div className="col-12 my-2">
                                              <textarea
                                                className="form-control"
                                                placeholder="Write a Message"
                                                value={
                                                  answers?.find(
                                                    (item) => item.id === que.id
                                                  )?.description ?? ""
                                                }
                                                disabled={
                                                  (isSignedOff &&
                                                    transactionData?.isLocked ===
                                                      true) ||
                                                  userType ===
                                                    PermissionType.manager ||
                                                  userType ===
                                                    PermissionType.admin
                                                }
                                                onChange={(e) =>
                                                  handleTextAreaChange(
                                                    que.id,
                                                    e
                                                  )
                                                }
                                                onBlur={() => {
                                                  saveAnswers(answers);
                                                }}
                                              />
                                            </div>
                                          </>
                                        )}
                                      {transactionData?.isLocked === true &&
                                        answers.map((ans) => {
                                          if (
                                            ans.id === que.id &&
                                            ans.description
                                          ) {
                                            return (
                                              <div
                                                className="container"
                                                key={ans.id}
                                              >
                                                <div className="row my-1">
                                                  <div className="col-sm-1 col-12 text-start">
                                                    <p className="mb-0">Ans.</p>
                                                  </div>
                                                  <div className="col-sm-11 col-12 text-start">
                                                    <p className="mb-0">
                                                      {ans.description}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                        </div>
                        {isAllowToAccess(
                          UserPermissionType.reco,
                          UserActionType.signoff
                        ) &&
                          ((userType === PermissionType.admin &&
                            !transactionData?.recoSignOffList.find(
                              (item) => item.signOffUserType === "ADMIN"
                            )) ||
                            (userType === PermissionType.staff &&
                              !transactionData?.recoSignOffList.find(
                                (item) =>
                                  item.signOffUserType === "LOCATION_STAFF"
                              )) ||
                            (userType === PermissionType.manager &&
                              !transactionData?.recoSignOffList.find(
                                (item) =>
                                  item.signOffUserType === "LOCATION_MANAGER"
                              ))) && (
                            <div className="my-2">
                              <div className="container">
                                {transactionData?.recoType ===
                                  RecoType.monthly &&
                                  selectedRecoType === RecoType.monthly && (
                                    <div className="row ">
                                      <div className="col-12">Comment:</div>
                                      <div className="col-12 my-2">
                                        <textarea
                                          className="form-control"
                                          placeholder="Write a Message"
                                          maxLength={100}
                                          value={signOffName?.comment}
                                          onChange={(e) =>
                                            setSignOffName({
                                              ...signOffName,
                                              comment: e.target.value,
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          )}

                        {/* )} */}

                        <div className="my-2">
                          <div className="container">
                            <div className="row">
                              <div className="col-sm-2 col-12 text-start">
                                Title
                              </div>
                              <div className="col-sm-4 col-12 text-start">
                                Date
                              </div>
                              <div className="col-sm-4 col-12 text-start">
                                Name
                              </div>
                              <div className="col-sm-2 col-12 text-start"></div>
                            </div>
                          </div>
                          {transactionData?.recoSignOffList &&
                          transactionData.recoSignOffList.length > 0
                            ? transactionData.recoSignOffList.map(
                                (item, index) => (
                                  <div className="container" key={index}>
                                    <div className="row py-1 align-items-center">
                                      <div className="col-sm-2 col-12">
                                        <p className="mb-0">
                                          {item?.signOffUserType ===
                                          "LOCATION_STAFF"
                                            ? "Administrator"
                                            : item?.signOffUserType ===
                                              "LOCATION_MANAGER"
                                            ? "Manager"
                                            : "Head Office"}
                                        </p>
                                      </div>
                                      <div className="col-sm-4 col-12">
                                        <p className="mb-0 w-80">
                                          {moment(item?.signOffDate).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </p>
                                      </div>
                                      <div className="col-sm-4 col-12">
                                        <div className="d-flex ">
                                          <p className="mb-0 w-80">
                                            {item?.signOffName}{" "}
                                            {item?.comment &&
                                              item.comment.trim().length >
                                                0 && (
                                                <span
                                                  className="cursor-pointer"
                                                  onClick={() => {
                                                    setCommentModal(true);
                                                    setCommentData(
                                                      item.comment
                                                    );
                                                  }}
                                                >
                                                  <Icon
                                                    className={"fs-4"}
                                                    name="info"
                                                  />
                                                </span>
                                              )}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-sm-2 col-12">
                                        {userType === PermissionType.staff &&
                                          item?.signOffUserType ===
                                            "LOCATION_STAFF" &&
                                          transactionData?.isLocked ===
                                            true && (
                                            <>
                                              {transactionData?.isUnlockRequested ===
                                              false ? (
                                                <ActionButton
                                                  title="Unlock Request"
                                                  size="md"
                                                  className=""
                                                  disabled={""}
                                                  onClick={
                                                    handleRequestToUnlock
                                                  }
                                                />
                                              ) : (
                                                transactionData?.isLocked ===
                                                  true && (
                                                  <p className="mb-0 text-center">
                                                    Already Unlock Requested
                                                  </p>
                                                )
                                              )}
                                            </>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )
                              )
                            : null}

                          {isAllowToAccess(
                            UserPermissionType.reco,
                            UserActionType.signoff
                          ) &&
                            userType === PermissionType.admin &&
                            !transactionData?.recoSignOffList.find(
                              (item) => item.signOffUserType === "ADMIN"
                            ) && (
                              <RecoDateNameComponent
                                headTitle={"Head Office"}
                                maxDate={new Date()}
                                onDateChange={(newDate) =>
                                  setSignOffDate(newDate)
                                }
                                dateSelected={signOffDate}
                                nameValue={signOffName?.name || ""}
                                onNameChange={(text) => {
                                  setSignOffName({
                                    ...signOffName,
                                    name: text,
                                  });
                                }}
                                signOffDisable={
                                  (!areAllCheckboxesChecked() &&
                                    selectedRecoType !== RecoType.weekly) ||
                                  // !areAllCheckboxesChecked() ||
                                  !signOffName?.name
                                }
                                onSignOff={() => onSignOff()}
                              />
                            )}
                          {userType === PermissionType.staff &&
                            !transactionData?.recoSignOffList.find(
                              (item) =>
                                item.signOffUserType === "LOCATION_STAFF"
                            ) && (
                              <RecoDateNameComponent
                                headTitle={"Administrator"}
                                maxDate={new Date()}
                                datePickerDisable={
                                  transactionData?.isLocked === true
                                }
                                onDateChange={(newDate) =>
                                  setSignOffDate(newDate)
                                }
                                dateSelected={signOffDate}
                                nameDisable={transactionData?.isLocked === true}
                                nameValue={signOffName?.name || ""}
                                onNameChange={(text) => {
                                  setSignOffName({
                                    ...signOffName,
                                    name: text,
                                  });
                                }}
                                signOffDisable={
                                  (!areAllCheckboxesChecked() &&
                                    selectedRecoType !== RecoType.weekly &&
                                    !selectedRecoDate) ||
                                  !signOffName?.name ||
                                  (!transactionData?.recoRealBalance
                                    ?.realBankBalance &&
                                    !transactionData?.recoRealBalance
                                      ?.realCashBalance)
                                }
                                onSignOff={() => onSignOff()}
                              />
                            )}
                          {userType === PermissionType.manager &&
                            !transactionData?.recoSignOffList.find(
                              (item) =>
                                item.signOffUserType === "LOCATION_MANAGER"
                            ) && (
                              <RecoDateNameComponent
                                headTitle={"Manager"}
                                maxDate={new Date()}
                                // datePickerDisable={
                                //   transactionData?.isLocked === true
                                // }
                                onDateChange={(newDate) =>
                                  setSignOffDate(newDate)
                                }
                                dateSelected={signOffDate}
                                nameValue={signOffName?.name || ""}
                                onNameChange={(text) => {
                                  setSignOffName({
                                    ...signOffName,
                                    name: text,
                                  });
                                }}
                                signOffDisable={
                                  (!areAllCheckboxesChecked() &&
                                    selectedRecoType !== RecoType.weekly) ||
                                  // !areAllCheckboxesChecked() ||
                                  !signOffName?.name
                                }
                                onSignOff={() => onSignOff()}
                              />
                            )}
                        </div>
                      </div>
                    </Card>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Col>
            <Col lg={4}>
              <Card className="card-bordered h-50">
                <div className="overflow-auto">
                  <div className="px-2 py-1 fs-6">
                    <p>Unpresented Cheque</p>
                  </div>
                  <div className="border-card-end"></div>
                  <div>
                    {transactionData?.unpresentedCheques &&
                    transactionData.unpresentedCheques.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table mt-1">
                          <thead>
                            <th>Date</th>
                            <th>Cheque No.</th>
                            <th>Amount</th>
                            <th></th>
                          </thead>
                          <tbody>
                            {transactionData?.unpresentedCheques.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(item?.date).format("DD-MM-YYYY")}
                                  </td>
                                  <td>{item?.chequeNo}</td>
                                  <td>
                                    {numberFormatter(
                                      CURRENCY_CODE,
                                      "" + (item?.amount ?? 0)
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <span
                                      size="sm"
                                      color="p"
                                      className="cursor-pointer text-primary"
                                      onClick={() =>
                                        handleClearButtonCheque(item)
                                      }
                                    >
                                      Clear
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-center py-2 align-v-center">
                        No data found for Unpresented Cheque
                      </p>
                    )}
                  </div>
                </div>
              </Card>
              <Card className="card-bordered h-50 mt-2">
                <div className="overflow-auto">
                  <div className="px-2 py-1 fs-6">
                    <p>Unpresented Lodgement</p>
                  </div>
                  <div className="border-card-end"></div>
                  <div className="table-container">
                    {transactionData?.unpresentedlodgement &&
                    transactionData.unpresentedlodgement.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table mt-1">
                          <thead>
                            <th>Date</th>
                            <th>Lodgement No.</th>
                            <th>Amount</th>
                            <th></th>
                          </thead>
                          <tbody>
                            {transactionData?.unpresentedlodgement.map(
                              (item, index) => (
                                <tr key={index}>
                                  <td>
                                    {moment(item?.date).format("DD-MM-YYYY")}
                                  </td>
                                  <td>{item?.lodgementNumber}</td>
                                  <td>
                                    {numberFormatter(
                                      CURRENCY_CODE,
                                      "" + (item?.amount ?? 0)
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <span
                                      size="sm"
                                      color="p"
                                      className="cursor-pointer text-primary"
                                      onClick={() =>
                                        handleClearButtonLodgement(item)
                                      }
                                    >
                                      Clear
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="text-center py-2">
                        No data found for Unpresented Lodgement
                      </p>
                    )}
                  </div>
                </div>
              </Card>
              <DateModal
                title={"Select Clear Date"}
                isOpen={isModalOpen}
                toggle={toggleModal}
                selectedItem={selectedData}
                handleSave={() => loadTransaction()}
              />
            </Col>
          </Row>
        </Block>

        <RecoCommentModal
          visible={commentModal}
          item={commentData}
          onClosed={() => {
            setCommentModal(false);
          }}
        />
      </Content>
      <LoadingComponent isLoading={initScreen} />
    </React.Fragment>
  );
};

export default Reco;
