import React from "react";
import { apiService } from "../../../services/api";
import { APIServicePath } from "../../../services/api/api-service-impl";
import {
  toTransaction,
  toTransactionList,
} from "../Transaction/TransactionData";
import { AnnsBadge, Icon, TableTitle } from "../../../components/Component";
import moment from "moment";
import CustomPopOver from "../Transaction/CustomPopOver";
import { CURRENCY_CODE, numberFormatter } from "../../../utils";
import {
  UserActionType,
  UserPermissionType,
  isAllowToAccess,
} from "../../../services/permission/permission";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { RecoType } from "../../../utils/Utils";

//------------------------------API------------------------------->
export function fetchTransactionReco(params, callback) {
  apiService
    .postCall(APIServicePath.transactionReco, params)
    .then((data) => {
      let result = toReco(data);
      callback(result, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchRealBalance(params, callback) {
  apiService
    .postCall(APIServicePath.realBalance, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchLockUnlock(params, callback) {
  apiService
    .postCall(APIServicePath.lockUnlock, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchSignOff(params, callback) {
  apiService
    .postCall(APIServicePath.recoSignOff, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchQuestionList(callback) {
  apiService
    .postCall(APIServicePath.questionlist)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchRecoPdf(params, callback) {
  apiService
    .postCall(APIServicePath.recoPdf, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchUnlockRequest(params, callback) {
  apiService
    .postCall(APIServicePath.requestUnlock, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

var RecoDateListToken = {};
export function fetchRecoDateList(params, callback) {
  RecoDateListToken?.cancel && RecoDateListToken?.cancel();
  apiService
    .postCallWithCancelSignal(
      APIServicePath.recoDateList,
      params,
      RecoDateListToken
    )
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

export function fetchPendingRecoList(params, callback) {
  apiService
    .postCall(APIServicePath.pendingReco, params)
    .then((data) => {
      callback(data, null);
    })
    .catch((error) => {
      callback(null, error);
    });
}

//------------------------------Converter------------------------------->
function toReco(response) {
  if (!response) return null;
  let unpresentedCheques = toTransactionList(response.unpresentedChequeList);
  let unpresentedlodgement = toTransactionList(
    response.unpresentedLodgementList
  );
  let totalResidentBalance =
    response?.totalResidentAmount?.active +
    response?.totalResidentAmount?.inactive;

  let item = {
    adjustedBankAmount: parseFloat(
      (response?.adjustedBankAmount ?? 0).toFixed(2)
    ),
    bankFee: parseFloat((response?.bankFee ?? 0).toFixed(2)),
    suspenceBalance: parseFloat((response?.suspenceBalance ?? 0).toFixed(2)),
    totalBalance: parseFloat((response?.totalBalance ?? 0).toFixed(2)),
    totalBankBalance: parseFloat((response?.totalBankBalance ?? 0).toFixed(2)),
    totalCashtin: parseFloat((response?.totalCashtin ?? 0).toFixed(2)),
    totalResidentAmount: parseFloat(totalResidentBalance.toFixed(2)),
    totalActiveResidentAmount: parseFloat(
      (response?.totalResidentAmount?.active ?? 0).toFixed(2)
    ),
    totalInActiveResidentAmount: parseFloat(
      (response?.totalResidentAmount?.inactive ?? 0).toFixed(2)
    ),
    totalUnpresentedAmount: parseFloat(
      (response?.totalUnpresentedAmount ?? 0).toFixed(2)
    ),
    totalUnpresentedLodgements: parseFloat(
      (response?.totalUnpresentedLodgements ?? 0).toFixed(2)
    ),
    unpresentedCheques: unpresentedCheques,
    unpresentedlodgement: unpresentedlodgement,
    recoRealBalance: response?.recoRealBalance,
    isLocked: response?.isLocked === 1 ? true : false,
    cashExplanation: response?.recoRealBalance?.cashExplanation,
    bankExplanation: response?.recoRealBalance?.bankExplanation,
    recoSignOffList: response?.isLocked === 1 ? response?.recoSignOffList : [],
    isUnlockRequested:
      response?.isUnlockRequested === 1 || response?.isUnlockRequested === true
        ? true
        : false,
    answers: response?.signOffAnswers ?? [],
    recoType: response?.recoType ?? "MONTHLY",
  };
  return item;
}

export const pendingRecoDataTable = (userType, onMoveReco, isDashboardPage) => {
  return [
    {
      name: <TableTitle title={"Date/Month"} />,
      selector: (row) => {
        return row?.recoType === RecoType.monthly
          ? moment(row?.fromDate).format("MMM YYYY")
          : row?.recoType === RecoType.weekly
          ? moment(row?.fromDate).format("DD-MM-YYYY")
          : "N/A";
      },
      width: "180px",
    },

    {
      name: <TableTitle title={"Reco type"} />,
      selector: (row) => row?.recoType ?? "N/A",
      width: "180px",
    },

    {
      name: <TableTitle title={"Signoff name"} />,
      selector: (row) => {
        const locationManager = row?.recoSignOff?.find(
          (item) => item.signOffUserType === "LOCATION_MANAGER"
        );

        if (locationManager) {
          // If a "LOCATION_MANAGER" is found, return their name
          return locationManager.signOffName;
        }

        const allSignOffNames = row?.recoSignOff?.map(
          (item) => item.signOffName
        );

        // If no "LOCATION_MANAGER" but other signoff names exist, return them as a concatenated string
        if (allSignOffNames && allSignOffNames.length > 0) {
          return allSignOffNames.join(", ");
        }

        // If neither a "LOCATION_MANAGER" nor any signoff names exist, return "N/A"
        return "N/A";
      },
    },

    {
      name: <TableTitle title={""} align="end" />,
      right: true,
      width: "70px",
      cell: (row) => {
        return (
          <>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="text-soft dropdown-toggle btn btn-icon btn-trigger table-edit-btn"
                onClick={() => {
                  onMoveReco(row?.recoType, row?.fromDate);
                }}
              >
                <Icon name={"eye"}></Icon>
              </DropdownToggle>
            </UncontrolledDropdown>
          </>
        );
      },
    },
  ];
};
